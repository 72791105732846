import { BlockStack, InlineGrid, Page, Text } from "@shopify/polaris";
import { lazy } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { BANNER_GET_STARTED, BANNER_VERIFY_STORE } from "storeseo-enums/cacheKeys";
import { AI_OPTIMIZER, IMAGE_OPTIMIZER } from "storeseo-enums/subscriptionAddonGroup";
import AppEmbedWarningBanner from "../components/common/AppEmbedWarningBanner.jsx";
import UsageLimitCard from "../components/common/UsageLimitCard.jsx";
import GetStartedCard from "../components/dashboard/GetStartedCard.jsx";
import PartnershipBanner from "../components/dashboard/PartnershipBanner.jsx";
import SEOGuideCard from "../components/dashboard/SEOGuideCard.jsx";
import ScheduleACallCard from "../components/dashboard/ScheduleACallCard.jsx";
import StayTunedCard from "../components/dashboard/StayTunedCard.jsx";
import VerifyStoreCard from "../components/dashboard/VerifyStoreCard.jsx";
import SubscriptionSuccessModal from "../components/modals/SubscriptionSuccessModal.jsx";
import StatisticsRow from "../components/statistics/StatisticsRow.jsx";
import { HELP_URLS, PAGE_TEMPLATES } from "../config/index.js";
import useUserAddon from "../hooks/useUserAddon";
import { getCheckoutPath } from "../utility/helpers.jsx";

const ExclusivePartnershipCard = lazy(() => import("@/components/dashboard/ExclusivePartnershipCard.jsx"));
const OtherAppsByStoreware = lazy(() => import("@/components/dashboard/OtherAppsByStoreware.jsx"));
const WhatsNewSection = lazy(() => import("@/components/dashboard/WhatsNewSection.jsx"));
const AutoOptimizerCard = lazy(() => import("@/components/dashboard/AutoOptimizerCard.jsx"));

export default function HomePage() {
  const user = useSelector((state) => state.user);
  const hiddenBanner = useSelector((state) => state.hiddenBanner);
  const { addons } = useUserAddon();

  const hasGetStartedRow = hiddenBanner[BANNER_GET_STARTED] || hiddenBanner[BANNER_VERIFY_STORE];
  const hasVerifyCard = !user.isPremium && !user.isVerified && hiddenBanner[BANNER_VERIFY_STORE];
  const hasMultiColumnGetStarted = hasVerifyCard && hiddenBanner[BANNER_GET_STARTED];
  const getStartedColumnCount = hasMultiColumnGetStarted ? 2 : 1;
  const checkoutPath = getCheckoutPath(user);
  const addonsColumnCount = Math.min(addons.length, 2);

  return (
    <Page>
      <BlockStack gap="400">
        <WelcomeSection />

        <StatisticsRow />

        {hasGetStartedRow && (
          <InlineGrid
            columns={{ xs: 1, sm: 1, md: getStartedColumnCount, lg: getStartedColumnCount, xl: getStartedColumnCount }}
            gap={400}
          >
            <GetStartedCard />

            {hasVerifyCard && <VerifyStoreCard />}
          </InlineGrid>
        )}

        <AppEmbedWarningBanner page={PAGE_TEMPLATES.DASHBOARD} />

        <WhatsNewSection />

        {/* <AiOptimizerPromoCard /> */}

        <InlineGrid
          columns={{ xs: 1, sm: 1, md: addonsColumnCount, lg: addonsColumnCount, xl: addonsColumnCount }}
          gap="400"
        >
          <UsageLimitCard
            title="Image Optimizer"
            group={IMAGE_OPTIMIZER}
            learnMoreButton={{
              title: "How to increase my monthly usage limits?",
              url: HELP_URLS.IMAGE_OPTIMIZER,
            }}
            action={{
              content: "Increase limit",
              url: checkoutPath,
            }}
          />
          <UsageLimitCard
            title="AI Content Optimizer"
            group={AI_OPTIMIZER}
            learnMoreButton={{
              title: "What do i do if i need more credits for AI Content Optimizer?",
              url: HELP_URLS.AI_OPTIMIZER,
            }}
            action={{
              content: "Increase limit",
              url: "/credit-bundles",
            }}
          />
        </InlineGrid>

        <AutoOptimizerCard />

        <ScheduleACallCard />

        <StayTunedCard />

        <OtherAppsByStoreware />

        <BlockStack>
          <SEOGuideCard />

          <ExclusivePartnershipCard />
        </BlockStack>

        <PartnershipBanner />
      </BlockStack>

      {user?.isNewlyUpgraded && <SubscriptionSuccessModal />}
    </Page>
  );
}

function WelcomeSection() {
  const { t } = useTranslation();

  return (
    <BlockStack
      gap="200"
      align="center"
    >
      <Text
        variant="heading2xl"
        as="h1"
      >
        {t("Welcome to StoreSEO")}
      </Text>
      <Text
        variant="bodyLg"
        as={"p"}
      >
        {t("Drive sales and traffic with the power of StoreSEO and improve your search engine ranking")}
      </Text>
    </BlockStack>
  );
}
