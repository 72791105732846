export const useThirdPartyScripts = () => {
  return {
    crisp(user) {
      if (user.shop) {
        const websiteId = process.env?.CRISP_WEBSITE_ID || "57c11e5d-c42e-4758-a784-9a30be2fac1a";
        window.$crisp = window.$crisp || [];
        window.CRISP_TOKEN_ID = user.shop;
        window.CRISP_WEBSITE_ID = websiteId;
        $crisp.push(["set", "user:email", [user.email]]);
        $crisp.push([
          "set",
          "session:data",
          [
            [
              ["shop-name", user.shopName],
              ["domain", user.shop],
              ["url", user.url],
              ["plan", user.planName],
              ["total-products", user.shopifyProductCount],
            ],
          ],
        ]);
        (function () {
          let d = document;
          let s = d.createElement("script");
          s.src = "https://client.crisp.chat/l.js";
          s.async = true;
          d.getElementsByTagName("head")[0].appendChild(s);
        })();
      }
    },

    googleAnalytics() {
      const analyticsId = process.env?.GOOGLE_ANALYTICS_ID || "G-8PB6Q28L9E";
      const script = document.createElement("script");
      script.src = `https://www.googletagmanager.com/gtag/js?id=${analyticsId}`;
      document.body.appendChild(script);
      window.dataLayer = window.dataLayer || [];

      function gtag() {
        dataLayer.push(arguments);
      }

      gtag("js", new Date());
      gtag("config", analyticsId);
    },
  };
};
