import { PRODUCT, PAGE, ARTICLE, COLLECTION } from "storeseo-enums/analysisEntityTypes";
import { EventStreamContentType, fetchEventSource } from "@microsoft/fetch-event-source";
import { API_PREFIX } from "../useAuthenticatedFetch";
import { useDispatch } from "react-redux";
import { setCreatingState, setMetaDesc, setMetaTitle, setTags } from "../../store/features/AiContent";
import { useApiClient } from "../useApiClient";
import { showNotification } from "@/utility/helpers";
import { useTranslation } from "react-i18next";
import { autoAiOptimizationAPISchema } from "storeseo-schema/settings/autoAiOptimization";

const endpoints = {
  [PRODUCT]: "/ai/generate-product-content",
  [COLLECTION]: "/ai/generate-collection-content",
  [PAGE]: "/ai/generate-page-content",
  [ARTICLE]: "/ai/generate-article-content",
};

export const useAiContentApi = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const apiClient = useApiClient();

  const defaultConfigs = {
    openWhenHidden: true,
    method: "POST",
    headers: {
      accept: "*/*",
      "content-type": "application/json",
    },
  };
  /**
   *
   * @param {Response} response
   * @returns
   */
  const defaultOnOpen = async (response) => {
    if (response.ok && response.headers.get("content-type") === EventStreamContentType) {
      return;
    } else if (response.headers.get("content-type") == "application/json") {
      throw await response.json();
    } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
      throw await response.json();
    }
    return;
  };

  /**
   * generates ai content based on the given params
   * @param {{title: string, description: string, focusKeyword: string, type: PRODUCT | PAGE | ARTICLE | COLLECTION}}
   */
  const generateAiContent = async ({ title, description, focusKeyword, type = PRODUCT }) => {
    try {
      let creditUsage;

      await fetchEventSource(`${API_PREFIX}${endpoints[type]}`, {
        ...defaultConfigs,
        body: JSON.stringify({
          title,
          description,
          focusKeyword,
        }),
        async onopen(response) {
          return await defaultOnOpen(response);
        },
        onmessage(ev) {
          try {
            const data = JSON.parse(ev.data);
            const { content, creditUsage: uses } = data;
            dispatch(setCreatingState(false));
            dispatch(setMetaTitle(content.meta_title));
            dispatch(setMetaDesc(content.meta_description));
            dispatch(setTags(content.tags));
            creditUsage = uses;
          } catch (err) {
            throw err;
          }
        },
        onerror(err) {
          throw err;
        },
      });

      return { creditUsage };
    } catch (error) {
      throw await error;
    }
  };

  /**
   * generates ai content based on the given params
   * @param {{ image: {id: string, src: string, product: object}}}
   * @param {import("react").Dispatch<import("react").SetStateAction<string>>} setAltText
   * @param {import("react").Dispatch<import("react").SetStateAction<boolean>} setIsGenerating
   */
  const generateImageAltText = async ({ image = [] }, setAltText, setIsGenerating) => {
    try {
      let creditUsage;

      await fetchEventSource(`${API_PREFIX}/ai/generate-image-alt-text`, {
        ...defaultConfigs,
        body: JSON.stringify({
          image,
        }),
        async onopen(response) {
          return await defaultOnOpen(response);
        },
        onmessage(ev) {
          try {
            const data = JSON.parse(ev.data);
            const { content, creditUsage: uses } = data;
            setAltText(content);
            setIsGenerating(false);
            creditUsage = uses;
          } catch (err) {
            throw err;
          }
        },
        onerror(err) {
          throw err;
        },
      });

      return { creditUsage };
    } catch (error) {
      throw await error;
    }
  };

  /**
   *
   * @param {images: {id: string}[]} images
   * @returns
   */
  const generateBulkImageAltText = async ({ images }) => {
    try {
      const data = await apiClient("/ai/generate-image-alt-text/queue", {
        method: "POST",
        body: JSON.stringify({
          images,
        }),
      });
      showNotification({ message: t(data?.message) });
      return data;
    } catch (err) {
      throw new Error(err.message);
    }
  };

  /**
   *
   * @returns {Promise<import("yup").InferType<typeof autoAiOptimizationAPISchema>>}
   */
  const getAiAutoOptimizationSettings = async () => {
    try {
      const { data } = await apiClient("/ai/auto-optimizer-settings");
      return data;
    } catch (err) {
      throw new Error(err.message);
    }
  };

  /**
   * @param {import("yup").InferType<typeof autoAiOptimizationAPISchema>} settings
   * @returns {Promise<import("yup").InferType<typeof autoAiOptimizationAPISchema>>}
   */
  const updateAiAutoOptimizationSettings = async (settings) => {
    try {
      const { message, data } = await apiClient("/ai/auto-optimizer-settings", {
        method: "PUT",
        body: JSON.stringify(settings),
      });

      showNotification({ message: t(message) });

      return data;
    } catch (err) {
      throw new Error(err.message);
    }
  };

  return {
    generateAiContent,
    generateImageAltText,
    generateBulkImageAltText,
    getAiAutoOptimizationSettings,
    updateAiAutoOptimizationSettings,
  };
};
