import { BlockStack, Button, InlineStack, Modal, Text } from "@shopify/polaris";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useProductApi } from "../../hooks/index.js";
import { setProductSyncStatus } from "../../store/features/ProductSync.js";
import { updateUser } from "../../store/features/User.js";

const SubscriptionSuccessModal = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const productApi = useProductApi();

  const [active, setActive] = useState(true);

  const handleChange = useCallback(() => {
    setActive(!active);
    dispatch(updateUser({ isNewlyUpgraded: false }));
  }, [active]);

  const { mutate: startSyncOperation, isLoading: isStartingSyncOperation } = useMutation({
    mutationFn: productApi.syncProductsFromShopify,
    onSuccess: (status) => dispatch(setProductSyncStatus(status)),
  });

  const goToLink = (link) => {
    setActive(false);
    return navigate(link);
  };

  // const listItems = [
  //   {
  //     key: "productCount",
  //     label: "Sync and edit upto {{productCount}} products.",
  //     action: (
  //       <Button
  //         size={"slim"}
  //         outline
  //         onClick={startSyncOperation}
  //         loading={isStartingSyncOperation}
  //       >
  //         {t("Sync Now")}
  //       </Button>
  //     ),
  //   },
  //   { key: "rich-snippets", label: "Automatic product rich snippets for Google search results.", action: "" },
  //   { key: "google-connect", label: "Connect and Submit Sitemaps to Google.", action: "" },
  //   { key: "rich-snippets", label: "Manage your SEO Schema data.", action: "" },
  // ];

  return (
    <Modal
      open={active}
      onClose={handleChange}
      title={t("Congratulations On Upgrading To The {{planName}} Plan!", { planName: user?.planName })}
      primaryAction={{
        content: t("Ok, Got it"),
        onAction: handleChange,
      }}
    >
      <Modal.Section>
        <BlockStack gap="400">
          <Text
            as="p"
            tone="subdued"
          >
            {t("Take a look at the features that come with our {{planName}} plan", { planName: user?.planName })}
          </Text>
          <InlineStack
            gap="200"
            blockAlign="center"
            align="space-between"
          >
            <Text as="p">
              {t("Sync and optimize upto")} <strong>{user?.permission?.products || "unlimited"}</strong> {t("products")}
            </Text>
            <Button
              size={"slim"}
              onClick={startSyncOperation}
              loading={isStartingSyncOperation}
            >
              {t("Sync Now")}
            </Button>
          </InlineStack>
          <InlineStack
            gap="200"
            blockAlign="center"
            align="space-between"
          >
            <Text as="p">
              {t("Automatic product")} <strong>{t("rich snippets")}</strong> {t("for Google search results")}
            </Text>
          </InlineStack>
          <InlineStack
            gap="200"
            blockAlign="center"
            align="space-between"
          >
            <Text as="p">
              {t("Connect and Submit")} <strong>{t("Sitemaps")}</strong> {t("to Google")}
            </Text>
            <Button
              size={"slim"}
              onClick={() => goToLink("/settings/google-integration")}
            >
              {t("Configure")}
            </Button>
          </InlineStack>
          <InlineStack
            gap="200"
            blockAlign="center"
            align="space-between"
          >
            <Text as="p">
              {t("Optimize your store for")} <strong>{t("SEO Schema")}</strong>
            </Text>
            <Button
              size={"slim"}
              onClick={() => goToLink("/local-seo")}
            >
              {t("Manage")}
            </Button>
          </InlineStack>
        </BlockStack>
      </Modal.Section>
    </Modal>
  );
};

export default SubscriptionSuccessModal;
