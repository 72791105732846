import { Banner, Text } from "@shopify/polaris";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { PAGE_TEMPLATES } from "../../config";

/**
 * @param {string} template
 * @param {boolean} showAction
 * @returns
 */
const AppEmbedWarningBanner = ({ template = PAGE_TEMPLATES.DASHBOARD, showAction = true }) => {
  const { t } = useTranslation();
  const { appEmbedEnabled } = useSelector((state) => state.user);

  if (appEmbedEnabled) return null;

  return (
    <Banner
      tone="warning"
      title={template !== PAGE_TEMPLATES.FIX && t("App embed is not enabled")}
      action={showAction && { content: t("App Embed Settings"), url: "/settings/app-embed" }}
    >
      <Text
        as="p"
        tone="subdued"
      >
        {t(
          template === PAGE_TEMPLATES.SETTINGS
            ? "To enable some advanced features (i.e., SEO Schema, Google verification, Noindex/Nofollow), you need to enable the App Embed for StoreSEO from your Shopify settings."
            : "To enable some advanced features (i.e., SEO Schema, Google verification, Noindex/Nofollow), you need to enable the App Embed for StoreSEO from your Shopify settings. Go to the settings page and follow the instructions to enable it."
        )}
      </Text>
    </Banner>
  );
};

export default AppEmbedWarningBanner;
